
import { Route, Routes } from 'react-router-dom';
import LandingPage from "./Landingpage";
import Home from "./Home"
function App() {
  return (
    <>
    <Routes>
      <Route path='/' element={<LandingPage/>}/>
      <Route path='/chat' element={<Home/>}/>
  
    </Routes>
    
    </>
  );
}

export default App;
