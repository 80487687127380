// import React, { useState, useEffect } from "react";

// const Home = () => {
//   const [time, setTime] = useState(new Date());

//   useEffect(() => {
//     const timerID = setInterval(() => {
//       setTime(new Date());
//     }, 1000);

//     return () => {
//       clearInterval(timerID);
//     };
//   }, []);

//   const formatTime = (date) => {
//     const hours = date.getHours().toString().padStart(2, "0");
//     const minutes = date.getMinutes().toString().padStart(2, "0");
//     const seconds = date.getSeconds().toString().padStart(2, "0");
//     return `${hours}:${minutes}:${seconds}`;
//   };

//   const formatDateTime = (date) => {
//     return date.toISOString();
//   };
//   const [inputText, setInputText] = useState("");
//   const [messages, setMessages] = useState([]);
//   const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);

//   const questions = [
//     "Hello! Welcome to MY RIN BAZAAR. I'm here to help you with your loan application. May I have your name, please?",
//     "What is your date of birth?",
//     "What is your annual income?",
//     "What is the loan amount you are seeking?",
//   ];

//   useEffect(() => {
//     if (currentQuestionIndex < questions.length) {
//       const currentTime = new Date().toLocaleTimeString();
//       const newMessage = {
//         text: questions[currentQuestionIndex],
//         time: currentTime,
//       };
//       setMessages([...messages, newMessage]);
//     }
//   }, [currentQuestionIndex]);

//   const handleInputChange = (event) => {
//     setInputText(event.target.value);
//   };

//   const handleSendClick = () => {
//     if (inputText.trim()) {
//       const currentTime = new Date().toLocaleTimeString();
//       const userMessage = {
//         text: inputText,
//         time: currentTime,
//       };
//       setMessages([...messages, userMessage]);
//       setInputText("");
//       setCurrentQuestionIndex(currentQuestionIndex + 1);
//     }
//   };

//   const handleKeyPress = (event) => {
//     if (event.key === "Enter") {
//       handleSendClick();
//     }
//   };

//   return (
//     <>
//       <div class="container bootstrap snippets bootdeys">
//         <div class="col-md-7 col-xs-12 col-md-offset-2">
//           {/* <!-- Panel Chat --> */}
//           <div class="panel" id="chat">
//             <div class="panel-heading">
//               <h3 class="panel-title">
//                 <i class="icon wb-chat-text" aria-hidden="true"></i> Chat
//               </h3>
//             </div>
//             <div class="panel-body">
//               <div class="chats">
//                 <div class="chat chat-left">
//                   <div class="chat-avatar">
//                     <a
//                       class="avatar avatar-online"
//                       data-toggle="tooltip"
//                       href="#"
//                       data-placement="left"
//                       title=""
//                       data-original-title="Edward Fletcher"
//                     >
//                       <img
//                         src="https://bootdey.com/img/Content/avatar/avatar2.png"
//                         alt="..."
//                       />
//                       <i></i>
//                     </a>
//                   </div>
//                   <div className="chat-body">
//                     {messages.map((message, index) => (
//                       <div key={index} className="chat-content">
//                         <p>{message.text}</p>
//                         <time className="chat-time" dateTime={message.time}>
//                           {message.time}
//                         </time>
//                       </div>
//                     ))}
//                   </div>
//                   {/* <div class="chat-body">
//                     <div class="chat-content">
//                       <p>
//                         Hello! Welcome to MY RIN BAZAAR.
//                         <br /> I'm here to help you with your loan application.
//                         May I have your name, please?
//                       </p>
//                       <time class="chat-time" datetime="2015-07-01T11:39">
//                         11:39:57 am
//                       </time>
//                     </div>
//                   </div> */}
//                 </div>
//                 <div class="chat">
//                   <div class="chat-avatar">
//                     <a
//                       class="avatar avatar-online"
//                       data-toggle="tooltip"
//                       href="#"
//                       data-placement="right"
//                       title=""
//                       data-original-title="June Lane"
//                     >
//                       <img
//                         src="https://bootdey.com/img/Content/avatar/avatar1.png"
//                         alt="..."
//                       />
//                       <i></i>
//                     </a>
//                   </div>
//                   <div className="chat-body">
//                     {messages.map((message, index) => (
//                       <div key={index} className="chat-content">
//                         <p>{message}</p>
//                       </div>
//                     ))}
//                   </div>
//                 </div>
//               </div>
//             </div>
//             <div class="panel-footer">
//               <form onSubmit={(e) => e.preventDefault()}>
//                 <div className="input-group">
//                   <input
//                     type="text"
//                     className="form-control"
//                     placeholder="Say something"
//                     value={inputText}
//                     onChange={handleInputChange}
//                     onKeyPress={handleKeyPress}
//                   />
//                   <span className="input-group-btn">
//                     <button
//                       className="btn btn-primary"
//                       type="button"
//                       onClick={handleSendClick}
//                     >
//                       Send
//                     </button>
//                   </span>
//                 </div>
//               </form>
//               {/* <form>
//                 <div class="input-group">
//                   <input
//                     type="text"
//                     class="form-control"
//                     placeholder="Say something"
//                   />
//                   <span class="input-group-btn">
//                     <button class="btn btn-primary" type="button">
//                       Send
//                     </button>
//                   </span>
//                 </div>
//               </form> */}
//             </div>
//           </div>
//           {/* <!-- End Panel Chat --> */}
//         </div>
//       </div>
//     </>
//   );
// };

// export default Home;

import React, { useState, useEffect } from "react";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const Home = () => {
  const [time, setTime] = useState(new Date());
  const [inputText, setInputText] = useState("");
  const [showFileInput, setShowFileInput] = useState(true); // State to control input visibility

  const [messages, setMessages] = useState([
    // Initial messages
    {
      text: "Hello, how can I help you?",
      fromSystem: true,
      time: new Date().toLocaleTimeString(),
    },
  ]);
  // const [messages, setMessages] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [file, setFile] = useState(null);

  useEffect(() => {
    const timerID = setInterval(() => {
      setTime(new Date());
    }, 1000);

    return () => {
      clearInterval(timerID);
    };
  }, []);

  const questions = [
    "What type of loan are you interested in?",
    "Could you please share your Address details?",
    "What is your annual income?",
    "Do you currently work as a business owner or a salaried professional?",
    "Would you like to upload any documents?",
  ];

  useEffect(() => {
    if (currentQuestionIndex < questions.length) {
      const currentTime = new Date().toLocaleTimeString();
      const newMessage = {
        text: questions[currentQuestionIndex],
        time: currentTime,
        fromSystem: true, // Indicate this message is from the system
      };
      setMessages((prevMessages) => [...prevMessages, newMessage]);
    }
  }, [currentQuestionIndex]);

  const handleInputChange = (event) => {
    setInputText(event.target.value);
  };
  // const handleFileChange = async (event) => {
  //   const files = event.target.files;
  //   const fileArray = [];
  //   for (let i = 0; i < files.length; i++) {
  //     const reader = new FileReader();
  //     reader.onload = (e) => {
  //       const base64String = e.target.result;
  //       fileArray.push(base64String);
  //       if (fileArray.length === files.length) {
  //         setFile(fileArray);
  //       }
  //     };
  //     reader.readAsDataURL(files[i]);
  //   }
  //   console.log(files);
  //   handleImageSend(files)
  // };

  // const handleImageSend = async (files) => {
  //   const formData = new FormData();
  //   for (let i = 0; i < files.length; i++) {
  //     formData.append("file", files[i]); // Append each file individually
  //   }
  //   formData.append("Phone", localStorage.getItem("Phone"));
  //   try {
  //     const response = await fetch("https://agrasevaapi.netspacesoftware.site/api/AddImages", {
  //       method: "POST",
  //       body: formData,
  //     });
  //     console.log("API response:", response);
  //   } catch (error) {
  //     console.error("Error sending image:", error);
  //   }
  // };

  const handleFileChange = async (event) => {
    const files = event.target.files;
    const fileArray = [];

    for (let i = 0; i < files.length; i++) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const base64String = e.target.result;
        fileArray.push(base64String);

        if (fileArray.length === files.length) {
          setFile(fileArray);
          handleImageSend(fileArray);
        }
      };

      if (files.length > 0) {
        // Create a new message for the uploaded image
        const newMessage = {
          text: `${
            files.length > 1 ? "Files have" : "File has"
          } been uploaded.`,
          fromSystem: false,
          time: new Date().toLocaleTimeString(),
        };
        // Create a follow-up message
        const followUpMessage = {
          text: "Thank you for submitting your application. Our representatives will reach out to you soon.",
          fromSystem: true,
          time: new Date().toLocaleTimeString(),
        };
        // Update the messages state
        setMessages([...messages, newMessage, followUpMessage]);
        setShowFileInput(false);
      }

      reader.readAsDataURL(files[i]);
    }
  };

  const handleImageSend = async (fileArray) => {
    try {
      const response = await fetch(
        "https://agrasevaapi.netspacesoftware.site/api/AddImages",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            Photo: fileArray,
            Phone: localStorage.getItem("Phone"),
          }),
        }
      );

      console.log("API response:", response);
    } catch (error) {
      console.error("Error sending images:", error);
    }
  };

  const handleSendClick = async () => {
    if (inputText.trim()) {
      const currentTime = new Date().toLocaleTimeString();
      const userMessage = {
        text: inputText,
        time: currentTime,
        fromSystem: false, // Indicate this message is from the user
      };
      setMessages((prevMessages) => [...prevMessages, userMessage]);
      setInputText("");

      // Prepare data for API call
      const tblKey = ["LonType", "Address", "Income", "Occupation"][
        currentQuestionIndex
      ];
      const userData = {
        TblKey: tblKey,
        ValKey: inputText,
        Phone: localStorage.getItem("Phone"),
      };

      try {
        const response = await fetch(
          "https://agrasevaapi.netspacesoftware.site/api/EditDetails",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(userData),
          }
        );
        const responseData = await response.json();
        console.log("API response:", responseData);
        if (currentQuestionIndex === questions.length - 1 && file) {
          await handleImageSend(file);
        }
      } catch (error) {
        console.error("Error sending user data:", error);
        // Optionally, you can handle errors or display an error message to the user
      }
      // Move to the next question
      setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSendClick();
    }
  };

  return (
    <div className="container bootstrap snippets bootdeys">
      <div className="col-md-7 col-xs-12 col-md-offset-2">
        {/* Panel Chat */}
        <div className="panel" id="chat">
          <div className="panel-heading">
            <h3 className="panel-title">
              <i className="icon wb-chat-text" aria-hidden="true"></i> Chat
            </h3>
            <h3>Welcome to MY RIN BAZAAR {localStorage.getItem("Name")}</h3>
          </div>
          <div className="panel-body">
            <div className="chats">
              {messages.map((message, index) => (
                <div
                  key={index}
                  className={`chat ${
                    message.fromSystem ? "chat-left" : "chat-right"
                  }`}
                >
                  <div className="chat-avatar">
                    <a
                      className="avatar avatar-online"
                      data-toggle="tooltip"
                      href="#"
                      data-placement={message.fromSystem ? "left" : "right"}
                      title=""
                      data-original-title={
                        message.fromSystem ? "Edward Fletcher" : "User"
                      }
                    >
                      <img
                        src={
                          message.fromSystem
                            ? "https://bootdey.com/img/Content/avatar/avatar2.png"
                            : "https://bootdey.com/img/Content/avatar/avatar1.png"
                        }
                        alt="..."
                      />
                      <i></i>
                    </a>
                  </div>
                  <div className="chat-body">
                    <div className="chat-content">
                      <p className="sender-name">
                        {message.fromSystem
                          ? "Rin Bazaar"
                          : `${localStorage.getItem("Name")}`}
                      </p>
                      <p>{message.text}</p>
                      <time className="chat-time" dateTime={message.time}>
                        {message.time}
                      </time>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="panel-footer">
            <form onSubmit={(e) => e.preventDefault()}>
            {showFileInput && (
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Say something"
                  value={inputText}
                  onChange={handleInputChange}
                  onKeyPress={handleKeyPress}
                />
                <span className="input-group-btn">
                  <button
                    className="btn btn-primary"
                    type="button"
                    onClick={handleSendClick}
                  >
                    Send
                  </button>
                </span>
              </div>
            )}
              {currentQuestionIndex === questions.length - 1 && showFileInput &&(
                <div style={{ display: "flex", gap: "15px", margin: "10px" }}>
                  <FontAwesomeIcon icon={faUpload} className="file-icon" />
                  <input
                    type="file"
                    className="form-control-file"
                    onChange={handleFileChange}
                    multiple
                  />
                </div>
              )}
            </form>
          </div>
        </div>
        {/* End Panel Chat */}
      </div>
    </div>
  );
};

export default Home;
