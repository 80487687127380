import React, { useState } from "react";
import { useNavigate } from "react-router";
const LandingPage = ({ onProceed }) => {
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const navigate = useNavigate();
  const formData = new FormData();

  const handleSubmit = async (event) => {
    event.preventDefault();
    formData.append("Name", name);
    formData.append("Phone", phoneNumber);

    try {
      const response = await fetch(
        `https://agrasevaapi.netspacesoftware.site/api/AddRecord`,
        {
          method: "POST",
          body: formData,
        }
      );

      const data = await response.json();
      if (data && data.result.key === "record inserted") {
        localStorage.setItem("Phone", phoneNumber);
        localStorage.setItem("Name", name);
        navigate("/chat");
      } else {
        if (data.result[0].message === "Data not found") {
          alert.error("User Id Or Password Or Company Code Invalid");
        } else {
          alert.error(data.result[0].message);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleKeyPress = (e) => {
    const charCode = e.which || e.keyCode;
    if (
      charCode < 48 ||
      charCode > 57 ||
      (charCode !== 8 && e.target.value.length >= 10)
    ) {
      e.preventDefault();
    }
  };
  return (
    <div className="container bootstrap snippets bootdeys">
      <div className="col-md-7 col-xs-12 col-md-offset-2 myBox">
      <div className="panel-heading">
            <h3 className="panel-title">
              <i className="icon wb-chat-text" aria-hidden="true"></i> 
            </h3>
            <h3>Welcome to MY RIN BAZAAR</h3>
            <h5>Kindly fill this to continue</h5>
          </div>
        <form onSubmit={handleSubmit}  className="col-md-7">
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              className="form-control"
              id="name"
              required
              placeholder="Enter name"
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="name">Phone</label>
            <input
              type="text"
              className="form-control"
              id="name"
              required
              onKeyPress={handleKeyPress}
              minlength={10}
              placeholder="Enter Phone"
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
          </div>{" "}
          <div className="form-group">
            <button className="btn btn-primary" type="submit">Get Started</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LandingPage;
